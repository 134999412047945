import locations from 'constants/locations.json';

export const detectLocation = ({ routerLocation, cookieLocation, headerCountry, headerRegion, headerCity }) => {
  try {
    if (cookieLocation) {
      const foundLocation = locations.find((location) => location.slug == cookieLocation?.slug);
      if (foundLocation) return foundLocation;
    }
  } catch (e) {
    console.log(e);
  }

  if (headerCity) {
    const city = locations.find((loc) => loc.nameEn.toLowerCase() == headerCity.toLowerCase());
    if (city) return city;
  }

  if (headerRegion) {
    const region = locations.find((loc) => loc.nameEn.toLowerCase() == headerRegion.toLowerCase());
    if (region) return region;
  }

  if (headerCountry) {
    const country = locations.find((loc) => loc.nameEn.toLowerCase() == headerCountry.toLowerCase());
    if (country) return country;
  }

  try {
    if (routerLocation) {
      const foundLocation = locations.find((location) => location.id == routerLocation?.id);
      if (foundLocation) return foundLocation;
    }
  } catch (e) {
    console.log(e);
  }

  return locations[0];
};

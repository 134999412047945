import React, { useCallback, useEffect } from 'react';
import Router from 'next/router';
import ym, { YMInitializer } from 'react-yandex-metrika';

const WithYandexMetrika = (props) => {
  const { children } = props;

  const YANDEX_METRIKA_ID = 49655695;
  const enabled = process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_USE_METRICA;

  const hit = useCallback((url) => {
    if (enabled) {
      ym('hit', url);
    } else {
      console.log(`%c[YandexMetrika](HIT)`, `color: orange`, url);
    }
  }, []);

  useEffect(() => {
    hit(window.location.pathname + window.location.search);
    Router.events.on('routeChangeComplete', (url) => hit(url));
  }, []);

  return (
    <>
      {enabled && (
        <YMInitializer accounts={[YANDEX_METRIKA_ID]} options={{ webvisor: true, defer: true }} version='2' />
      )}
      {children}
    </>
  );
};

export default WithYandexMetrika;

import { API_ROOT } from 'modules/request';

//public
export const mainPath = () => '/';

//auth
export const signInPath = () => '/auth/sign-in';
export const signUpPath = () => '/auth/sign-up';
export const forgotPasswordPath = () => '/auth/forgot-password';
export const socialAuthPath = (provider = ':provider') => `${API_ROOT}/auth/${provider}`;
export const resetPasswordPath = () => '/auth/reset-password';
export const verifyEmailPath = () => '/auth/verify-email';

export const listingsPath = ({ slug = '', page = 1, location = 'worldwide' }) =>
  slug ? categoryPath({ slug, location, page }) : `/${location}/listings/${page}/`;
export const listingPath = (slug = ':slug') => `/listings/${slug}`;

export const categoriesPath = ({ location = 'worldwide' }) => `/${location}/categories`;
export const categoryPath = ({ slug = '', location = 'worldwide', page = 1 }) =>
  !!slug ? `/${location}/categories/${slug}/${page}/` : listingsPath();

export const authorPath = (id = ':id') => `/authors/${id}`;

//private
export const privateSettingsPath = () => '/private/settings';
export const privateListingsPath = () => '/private/listings';
export const privateAccountsPath = () => '/private/accounts';
export const privateAddListingPath = () => '/private/listings/add';
export const privateChatsPath = () => '/private/chats';
export const privateEditListingPath = (slug = ':slug') => `/private/listings/edit/${slug}`;

// API
export const apiLoginEndpoint = () => '/auth/login';
export const apiReloginEndpoint = ({ id }) => `/auth/relogin/${id}`;
export const apiProfileEndpoint = () => '/profile';

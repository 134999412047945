import { createContext, useContext } from 'react';

export const AuthContext = createContext({
  isAuth: () => false,
  data: null,
  signIn: () => {},
  signUp: () => {},
  signOut: () => {},
});

export const useAuthContext = () => useContext(AuthContext);

import React from 'react';
import { appWithTranslation } from 'next-i18next';
import { AuthProvider } from 'components/shared/Auth';
import { LocationProvider } from 'components/shared/Location';
import { CategoriesProvider } from 'components/shared/Categories';
import categories from 'constants/categories.json';
import locations from 'constants/locations.json';
import { WORLDWIDE_LOCATION } from 'constants/';
import { useCookies } from 'react-cookie';
import WithYandexMetrika from 'components/WithYandexMetrika';

import 'react-image-gallery/styles/css/image-gallery.css';
import 'tui-image-editor/dist/tui-image-editor.css';
import '../components/ant/css/empty.css';
function App({ Component, pageProps, router }) {
  const routerLocation = router?.query?.location;
  const currentLocation =
    (routerLocation && locations.find((l) => l.slug === router?.query?.location)) || WORLDWIDE_LOCATION;
  const [cookie] = useCookies(['accessToken']);
  const accessToken = cookie?.accessToken;
  return (
    <WithYandexMetrika>
      <AuthProvider accessToken={accessToken}>
        <LocationProvider defaultLocation={currentLocation}>
          <CategoriesProvider categories={categories}>
            <Component {...pageProps} />
          </CategoriesProvider>
        </LocationProvider>
      </AuthProvider>
    </WithYandexMetrika>
  );
}

export default appWithTranslation(App);

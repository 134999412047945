import { apiLoginEndpoint } from 'constants/path';
import { getRequest } from 'modules/request';

export const login = (email, password) =>
  getRequest().post(apiLoginEndpoint()).send({
    email,
    password,
  });

export const reg = (firstName, lastName, email, password) =>
  getRequest()
    .post('/auth/sign-up')
    .send({
      firstName,
      lastName,
      email,
      password,
    })
    .then((res) => res.body);

export const forgotPassword = (identity) =>
  getRequest()
    .post('/auth/forgot-password')
    .send({
      identity,
    })
    .then((res) => res.body);

export const resetPassword = (token, newPassword) =>
  getRequest()
    .post('/auth/reset-password')
    .send({
      token,
      newPassword,
    })
    .then((res) => res.body.accessToken);

export const resendVerifyEmail = (identity) =>
  getRequest().post('/auth/resend-verify').send({
    identity,
  });

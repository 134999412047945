import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';

import { login, reg, forgotPassword, resetPassword, resendVerifyEmail, AuthContext } from 'components/shared/Auth';

import jwt from 'jsonwebtoken';

import { isAuthPath, isPrivate, isValid } from 'components/shared/Auth/utils';
import { mainPath, signInPath } from 'constants/path';

export const AuthProvider = ({ children, ...props }) => {
  const [accessToken, setAccessToken] = React.useState(props.accessToken);
  const [authError, setAuthError] = React.useState();
  const [cookie, setCookie, removeCookie] = useCookies(['accessToken']);
  const { pathname, push, query } = useRouter();

  useEffect(() => {
    if (isPrivate(pathname) && !isValid(accessToken)) push(signInPath());
    if (isAuthPath(pathname) && isValid(accessToken)) push(mainPath());
  });

  const _handleSignIn = (email, password) =>
    login(email, password).then(({ body }) => {
      const { accessToken } = body;
      setAuthError(null);
      setAccessToken(accessToken);
      setCookie('accessToken', accessToken, { path: '/' });
    });

  const _handleSignUp = (firstName, lastName, email, password) => reg(firstName, lastName, email, password);

  const _handleSignInWithToken = (token) => {
    setAuthError(null);
    setAccessToken(token);
    setCookie('accessToken', token, { path: '/' });
  };

  const _handleSignOut = () => {
    removeCookie('accessToken', { path: '/' });
    setAccessToken(null);
    // to support logging out from all windows
    window.localStorage.setItem('logout', Date.now());
  };

  const _handleForgotPassword = (email) => forgotPassword(email);

  const _handleResetPassword = (password) => {
    const { token } = query;
    resetPassword(token, password)
      .then(() => push(signInPath()))
      .catch((error) => setAuthError(error));
  };

  const _handleResendVerifyEmail = (email) => resendVerifyEmail(email);

  return (
    <AuthContext.Provider
      value={{
        isAuth: isValid(accessToken),
        data: accessToken && jwt.decode(accessToken),
        accessToken,
        signIn: _handleSignIn,
        signUp: _handleSignUp,
        signOut: _handleSignOut,
        signInWithToken: _handleSignInWithToken,
        forgotPassword: _handleForgotPassword,
        resetPassword: _handleResetPassword,
        resendVerifyEmail: _handleResendVerifyEmail,
        authError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

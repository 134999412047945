import React, { useState } from 'react';

import { CategoriesContext } from './Context';

const getCategoriesList = (categories) => {
  const categoriesList = [];

  const flatCategories = (categories, subcategories) => {
    categories.push(...subcategories);
    for (const subcategory of subcategories) {
      flatCategories(categories, subcategory.subcategories);
    }
  };

  for (const category of categories) {
    categoriesList.push(category);
    flatCategories(categoriesList, category.subcategories);
  }

  return categoriesList;
};

export const CategoriesProvider = ({ children, ...props }) => {
  const [categories, setCategories] = useState(props.categories);
  const categoriesList = getCategoriesList(categories);

  const _handleSetCategories = (id) => {
    const renderCategory = (category) =>
      Object.assign(
        category,
        category.id === id && { render: true },
        category.id !== id && {
          subcategories: Object.assign(mapCategories(category.subcategories)),
        },
      );

    const mapCategories = (categories) => categories.map((category) => renderCategory(category));

    setCategories(mapCategories(categories));
  };

  const findCategory = (categoriesList, key, value) => categoriesList.find((c) => c[key] === value);

  return (
    <CategoriesContext.Provider
      value={{
        categories,
        findCategory: (key, value) => findCategory(categoriesList, key, value),
        renderCategory: _handleSetCategories,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

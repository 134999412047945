import request from 'superagent';

const PRODUCTION_API_ROOT = 'https://api.zotzon.com';

const agent = request.agent();
const chatAgent = request.agent();
const commentAgent = request.agent();

const prefixMiddleware = function (prefix) {
  return function (request) {
    if (request.url[0] === '/') {
      request.url = prefix + request.url;
    }

    return request;
  };
};

const getApiRoot = () => {
  if (process.env.NEXT_PUBLIC_API_ROOT) return process.env.NEXT_PUBLIC_API_ROOT;
  if (typeof location !== 'undefined')
    switch (location?.hostname) {
      case 'zotzon.com':
      case 'www.zotzon.com':
      case 'ru.zotzon.com':
      case 'en.zotzon.com':
        return PRODUCTION_API_ROOT;
      case 'staging.zotzon.com':
      case 'zotzon.vercel.app':
        return 'https://api.staging.zotzon.com';
      case 'alex.staging.zotzon.com':
        return 'https://api.alex.staging.zotzon.com';
      case 'andrey.staging.zotzon.com':
        return 'https://api.andrey.staging.zotzon.com';
      case 'sergey.staging.zotzon.com':
        return 'https://api.sergey.staging.zotzon.com';
    }
  return null;
};

const getChatApiRoot = () => {
  if (process.env.NEXT_PUBLIC_CHAT_API_ROOT) return process.env.NEXT_PUBLIC_CHAT_API_ROOT;
  if (typeof location !== 'undefined')
    switch (location?.hostname) {
      case 'zotzon.com':
      case 'www.zotzon.com':
      case 'ru.zotzon.com':
      case 'en.zotzon.com':
        return 'https://chat.zotzon.com';
      case 'staging.zotzon.com':
      case 'zotzon.vercel.app':
      case 'alex.staging.zotzon.com':
      case 'andrey.staging.zotzon.com':
      case 'sergey.staging.zotzon.com':
        return 'https://chat.staging.zotzon.com';
    }
  return null;
};

export const getImageHost = () => {
  // if (getApiRoot() === PRODUCTION_API_ROOT) return 'https://img.zotzon.com';
  // return 'https://imgstaging.zotzon.com';
  return 'https://img.zotzon.com';
};

export const API_ROOT = getApiRoot();
export const CHAT_API_ROOT = getChatApiRoot();

export const getRequest = (ctx = {}) => agent.use(prefixMiddleware(API_ROOT));
export const getChatRequest = (ctx = {}) => chatAgent.use(prefixMiddleware(CHAT_API_ROOT));
export const getCommentRequest = (ctx = {}) => commentAgent.use(prefixMiddleware('http://localhost/'));

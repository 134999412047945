import React from 'react';
import jwt from 'jsonwebtoken';

export const isAuthPath = (pathname) => pathname.startsWith('/auth/');

export const isPrivate = (pathname) => pathname.startsWith('/private/');

export const isValid = (accessToken) =>
  //prettier-ignore
  accessToken
    ? jwt.decode(accessToken)?.exp - Math.floor(Date.now() / 1000) > 0
    : false;

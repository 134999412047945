export const LANGUAGES = {
  en: 'English',
  ru: 'Русский',
};

export const PER_PAGE = 24;
export const VIP_PER_PAGE = 4;
export const MOBILE_WIDTH = 600;

export const HELP_REVALIDATION_TIME = 60 * 60 * 24; // 1 day in seconds
export const DYNAMIC_CONTENT_REVALIDATION_TIME = 60 * 30; // 30 minutes

export const WORLDWIDE_LOCATION = {
  type: 'worldwide',
  id: 0,
  nameEn: 'Worldwide',
  nameRu: 'Весь мир',
  slug: 'worldwide',
};

export const LIVE_CATEGORY = 1404;

export const LISTING_GALLERY_IMAGE_NUMBER = 20;
export const CARD_GALLERY_IMAGE_NUMBER = 3;
export const VIP_CARD_GALLERY_IMAGE_NUMBER = 5;

import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import locations from 'constants/locations.json';
import { LocationContext } from './Context';
import { detectLocation } from 'components/shared/Location';

const redirectWithLocation = (router, location) => {
  const { query } = router;
  if (!query?.location) return;
  if (query.location == location.slug) return;
  query.location = location.slug;
  router.push({ query, ...router });
};

export const LocationProvider = ({ defaultLocation, children }) => {
  const [currentLocation, setCurrentLocationState] = useState(defaultLocation);
  const [cookie, setCookie] = useCookies(['currentLocation']);
  const router = useRouter();

  const setCurrentLocation = (location) => {
    setCurrentLocationState(location);
    setCookie('currentLocation', JSON.stringify(location), { path: '/' });
    redirectWithLocation(router, location);
  };

  useEffect(() => {
    if (!router.isReady) return;
    setCurrentLocation(
      detectLocation({
        routerLocation: locations.find((l) => l.slug === router?.query?.location),
        cookieLocation: cookie,
      }),
    );
  }, [router.isReady]);

  return (
    <LocationContext.Provider
      value={{
        currentLocation,
        setCurrentLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
